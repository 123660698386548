.home-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .home-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 50px;
    opacity: 0;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;

    .text-zone {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 45%;
      padding: 20px;

      .photo {
        width: 280px;
        height: 300px;
        animation: fadeIn 1s 0s backwards;
        background-color: rgba(azure, 0.3);
        border-radius: 45px;
        transition: 0.2s;

        &:hover {
          scale: 1.05;
          transition: 0.2s;
          filter: drop-shadow(0 0 0.75rem azure);
        }
      }

      h1 {
        color: #086cd1;
        font-size: 45px;
        font-family: 'Coolvetica';
        font-weight: 400;
        line-height: 60px;
        margin: 10px 0;

        .name-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .job-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        img {
          width: 25px;
          opacity: 0;
          animation: rotateIn 1s linear both;
          animation-delay: 1.4s;
          margin-right: 5px;

          &:hover {
            filter: drop-shadow(0 0 0.75rem azure);
          }
        }
      }

      h2 {
        color: azure;
        font-weight: 400;
        font-size: 13px;
        font-family: sans-serif;
        letter-spacing: 3px;
        margin-bottom: 30px;
        margin-top: 10px;
        animation: fadeIn 1s 1.8s backwards;
      }
      .flat-button {
        color: tomato;
        width: 180px;
        height: 80px;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        justify-content: center;
        text-align: center;
        text-decoration: none;
        border: 1px solid tomato;
        border-radius: 4px;
        padding: 10px;
        margin-top: 40px;
        animation: fadeIn 1s 1.8s backwards;

        &:hover {
          background: tomato;
          color: #054a91;
          filter: drop-shadow(0 0 0.75rem tomato);
        }
      }

      .sosial {
        display: none;
      }
    }

    .logo-zone {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 45%;
      padding: 20px;
      top: -10%;

      svg {
        position: absolute;
        width: 350px;
        max-width: 85%;
        height: auto;
        filter: drop-shadow(0 0 0.75rem azure);
        animation-name: floating;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        top: -20px;
      }

      .solid-logo {
        position: absolute;
        width: 350px;
        max-width: 85%;
        height: auto;
        filter: drop-shadow(0 0 0.75rem azure);
        animation-name: floating;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        top: -20px;
      }

      @keyframes floating {
        0% {
          transform: rotateZ(30deg) translate(0, 0px);
        }
        50% {
          transform: rotateZ(30deg) translate(0, -15px);
        }
        100% {
          transform: rotateZ(30deg) translate(0, 0px);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .home-content {
      margin-left: 0;
      margin-top: -40px;
      .text-zone {
        .text-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .photo {
            margin-top: 10px;
            margin-bottom: 10px;
            width: 240px;
            height: 260px;
            scale: 0.8;
          }

          h1 {
            text-align: center;

            .job-container,
            .name-container {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
          }

          h2 {
            margin: 5px;
            text-align: center;
          }

          .flat-button {
            margin-top: 20px;
            height: 20px;
            width: 140px;
          }

          .sosial {
            display: flex;
            flex-direction: row;
            justify-content: end;
            height: 100%;
            margin: 15px;

            ul {
              display: flex;
              flex-direction: row;
              justify-content: center;
              gap: 20px;
              padding: 0px;

              li {
                list-style: none;
                text-decoration: none;
                text-align: center;
                padding: 2px;

                a {
                  color: azure;
                  font-size: medium;

                  &:hover {
                    color: tomato;
                    filter: drop-shadow(0 0 0.25rem tomato);
                  }
                }
              }
            }
          }
        }
      }

      .logo-zone {
        display: none;
      }
    }
  }
}
