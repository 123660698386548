.contact-page {
  width: 100%;
  will-change: content;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;

  .text-container {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;

    h1 {
      font-size: 45px;
      font-family: 'Coolvetica';
      color: #086cd1;
      font-weight: 400;
      margin-top: 0;
      position: relative;
    }

    p {
      font-style: 13px;
      color: azure;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }

      &:nth-of-type(4) {
        animation-delay: 1.4s;
      }
    }

    .contact-form {
      margin-top: 20px;

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          margin-bottom: 10px;
          overflow: hidden;
          display: block;
          position: relative;
          opacity: 0;
          animation: fadeInUp 2s 2s;
          animation-fill-mode: forwards;
          clear: both;
        }

        li.half {
          width: 49%;
          margin-left: 2%;
          float: left;
          clear: none;

          &:first-child {
            margin-left: 0;
          }
        }
      }

      input[type='text'],
      input[type='email'] {
        width: 100%;
        border: 0;
        background: rgba(218, 223, 225, 1);
        height: 50px;
        font-size: 16px;
        color: black;
        padding: 0 20px;
        box-sizing: border-box;
      }

      textarea {
        width: 100%;
        border: 0;
        background: rgba(218, 223, 225, 1);
        height: 50px;
        font-size: 16px;
        color: black;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
        border-radius: 1.5px;
      }

      .send-button {
        height: 40px;
        font-weight: 400;
        color: tomato;
        font-family: sans-serif;
        font-size: 13px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 5px;
        border: 1px solid tomato;
        float: right;
        border-radius: 4px;
        background-color: transparent;

        &:hover {
          background: tomato;
          color: #054a91;
          cursor: pointer;
          filter: drop-shadow(0 0 0.75rem tomato);
        }
      }

      .map-button {
        height: 40px;
        font-weight: 400;
        color: tomato;
        font-family: sans-serif;
        font-size: 13px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 5px;
        border: 1px solid tomato;
        float: right;
        border-radius: 4px;
        background-color: transparent;
        margin-right: 10px;
        text-align: center;
        display: none;

        &:hover {
          background: tomato;
          color: #054a91;
          cursor: pointer;
          filter: drop-shadow(0 0 0.75rem tomato);
        }
      }
    }

    .text-animate-hover {
      &:hover {
        color: tomato;
      }
    }
  }

  .map-container {
    position: absolute;
    background-color: rgba(5, 74, 145, 0.1);
    float: right;
    width: 50%;
    height: 100%;
    right: 0;

    iframe {
      width: 100%;
      height: 100%;
      position: relative;
      opacity: 0;
      animation: backInRight 1s 1.2s;
      animation-fill-mode: forwards;
    }
  }

  @media screen and (max-width: 768px) {
    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 90%;
      left: 5%;

      .contact-form {
        .map-button {
          display: block;
        }
      }
    }

    .map-container {
      display: none;
    }
  }
}
