.about-page {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .text-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 25%;
    width: 35%;
    margin-left: 15%;

    .skill-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 7.5px;
    }

    h1 {
      font-size: 45px;
      font-family: 'Coolvetica';
      color: #086cd1;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 0px;
    }

    p {
      font-style: 14px;
      color: azure;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }

      &:nth-of-type(4) {
        animation-delay: 1.4s;
      }
    }
    .text-animate-hover {
      &:hover {
        color: tomato;
      }
    }
  }

  .stage-cube-container {
    width: 350px;
    height: 350px;
    overflow: hidden;
    position: absolute;
    padding-top: 150px;
    top: 15%;
    right: 12.5%;
    scale: 1.15;

    .cubespinner {
      animation-name: spincube;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 12s;
      transform-style: preserve-3d;
      transform-origin: 100px 100px 0;
      margin-left: calc(50% - 100px);

      div {
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #4d4d4d;
        background: rgba(77, 77, 77, 0.4);
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px #4d4d4d;
      }

      .face1 {
        transform: translateZ(100px);
      }

      .face2 {
        transform: rotateY(90deg) translateZ(100px);
      }

      .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      }

      .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      }

      .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      }

      .face6 {
        transform: rotateX(-90deg) translateZ(100px);
      }
    }

    @keyframes spincube {
      from,
      to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
      }
      16% {
        transform: rotateY(-90deg);
      }
      33% {
        transform: rotateY(-90) rotateZ(90deg);
      }
      50% {
        transform: rotateY(-180deg) rotateZ(90);
      }
      66% {
        transform: rotateY(-270deg) rotateZ(90deg);
      }
      83% {
        transform: rotateX(90deg);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .text-container {
      position: relative;
      justify-content: flex-end;
      width: 100%;
      height: 90vh;
      margin-left: 0%;
      padding: 20px;

      h1 {
        font-size: 45px;
        margin-bottom: 0px;
      }
    }

    .stage-cube-container {
      top: -10%;
      right: calc(50% - 175px);
      scale: 0.8;
    }
  }
}
