.nav-bar nav {
  background: transparent;
  width: 50px;
  height: 100%;
  position: absolute;
  z-index: 3;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-bar nav .menu {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: center;
  height: 100%;
  margin-top: 10px;
  gap: 20px;
}

.nav-bar nav .menu a {
  font-size: 18px;
  color: azure;
  padding: 5px;
}

.nav-bar nav .menu a.active,
.nav-bar nav .menu a:hover {
  color: tomato;
  filter: drop-shadow(0 0 0.25rem tomato);
}

.nav-bar nav .sosial {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
  margin-bottom: 10px;
}

.nav-bar nav .sosial ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  gap: 10px;
}

.nav-bar nav .sosial ul li {
  list-style: none;
  text-decoration: none;
  text-align: center;
  padding: 2px;
}

.nav-bar nav .sosial ul li a {
  font-size: 15px;
  color: azure;
}

.nav-bar nav .sosial ul li a:hover {
  color: tomato;
  filter: drop-shadow(0 0 0.25rem tomato);
}

@media screen and (max-width: 768px) {
  .nav-bar nav {
    width: 100%;
    min-height: auto;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 0;
  }

  .nav-bar nav .menu {
    flex-direction: row-reverse;
    justify-content: space-around;
  }

  .nav-bar nav .menu a {
    padding: 10px;
  }

  .nav-bar nav .sosial {
    display: none;
  }
}
