html{
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: white;
  -webkit-font-smoothing: antialiased; 
  -moz-osx-font-smoothing: grayscale;
  background-color: #121214;
  overflow: hidden;
  display: block;
}

#particles canvas {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
}
